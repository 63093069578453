<template>
  <div>
    <!-- loading spinner -->
    <div v-if="loading" class="text-center">
      <div class="inline-block">
        <loading-spinner />
      </div>
    </div>

    <div v-if="!loading && account">
      <!--  breadcrumbs -->
      <account-breadcrumbs :account="account"></account-breadcrumbs>

      <form @submit.prevent="onSubmit">
        <card>
          <!-- header -->
          <template #header>
            <column-group>
              <manage-page-heading heading="VI Feed" :details="!product.enabled ? 'Enable this product to modify features' : ''"></manage-page-heading>

              <div class="justify-self-end self-end">
                <btn v-if="product.enabled" @click="onDisableProductClick">Disable</btn>
                <btn v-if="!product.enabled" @click="onEnableProductClick">Enable</btn>
              </div>
            </column-group>
          </template>

          <!-- fields -->
          <div :class="[`${!product.enabled ? 'text-gray' : ''}`]">
            <validated-field-group label="Service Period">
              <template #details>
                <p>Start and end date</p>
              </template>
              <div class="flex justify-between items-center">
                <date-picker v-model="product.startDate" name="vifeed.startDate" :disabled="!product.enabled" />

                <p class="px-1.5">to</p>

                <date-picker v-model="product.endDate" name="vifeed.endDate" :disabled="!product.enabled" />
              </div>

              <template #validation-message>
                <FieldErrorMessage :field="v$.product.startDate"></FieldErrorMessage>
              </template>
            </validated-field-group>

            <div class="bg-blue-light rounded-lg p-6">
              <div>
                <label class="font-bold leading-4 inline-block">Delivery Method</label>
                <div class="w-1/2 mb-2 block mt-3">
                  <radio-group>
                    <radio-group-item name="vifeed.deliveryMethod" value="sftp" v-model="product.deliveryMethod" label="SFTP" defaultValue :disabled="!product.enabled"></radio-group-item>
                    <radio-group-item name="vifeed.deliveryMethod" value="s3" v-model="product.deliveryMethod" label="S3" :disabled="!product.enabled"></radio-group-item>
                  </radio-group>
                  <FieldErrorMessage :field="v$.product.deliveryMethod"></FieldErrorMessage>
                </div>
                <div v-if="product.deliveryFrequency == 'daily'" class="mt-5">
                  <label><input type="checkbox" name="vifeed.sendCustomFile" v-model="product.sendCustomFile" value="false" class="mr-3" :disabled="!product.enabled">Send Custom File (only available for daily delivery)</label>
                </div>
              </div>


              <div class="mt-5">
                <validated-field-group label="Delivery Frequency">
                  <dropdown v-model="product.deliveryFrequency" name="vifeed.deliveryFrequency" :items="frequencies" :disabled="!product.enabled" />
                  <template #validation-message>
                    <FieldErrorMessage :field="v$.product.priority"></FieldErrorMessage>
                  </template>
                </validated-field-group>
              </div>

              <!------------------>
              <!--S3 Information-->
              <!------------------>
              <div v-if="product.deliveryMethod == 's3'">
                <p class="pb-2">
                  Credentials for S3 are not validated for correctness. Please confirm your entries.
                </p>
                <column-group :cols="3">
                  <div class="col-span-2 mr-3">
                    <vert-field-group label="S3 Bucket" required>
                      <text-field v-model="product.s3Bucket" name="vifeed.s3Bucket" :disabled="!product.enabled" />
                      <template #validation-message>
                        <FieldErrorMessage :field="v$.product.s3Bucket"></FieldErrorMessage>
                      </template>
                    </vert-field-group>
                  </div>
                  <div>
                    <vert-field-group label="S3 Region">
                      <text-field v-model="product.s3Region" name="vifeed.s3Region" :disabled="!product.enabled" />
                      <template #validation-message>
                        <FieldErrorMessage :field="v$.product.s3Region"></FieldErrorMessage>
                      </template>
                    </vert-field-group>
                  </div>
                </column-group>
                <column-group :cols="3" class="mt-5">
                  <div class="col-span-2 mr-3">
                    <vert-field-group label="S3 Access Key" required>
                      <text-field v-model="product.s3AccessKey" name="vifeed.s3AccessKey" :disabled="!product.enabled" />
                      <template #validation-message>
                        <FieldErrorMessage :field="v$.product.s3AccessKey"></FieldErrorMessage>
                      </template>
                    </vert-field-group>
                  </div>
                  <div>
                    <vert-field-group label="S3 Secret Key" required>
                      <text-field v-model="product.s3SecretKey" name="vifeed.s3SecretKey" :disabled="!product.enabled" />
                      <template #validation-message>
                        <FieldErrorMessage :field="v$.product.s3SecretKey"></FieldErrorMessage>
                      </template>
                    </vert-field-group>
                  </div>
                </column-group>
              </div>

              <!-------------------->
              <!--SFTP Information-->
              <!-------------------->
              <div v-if="product.deliveryMethod == 'sftp'">
                <column-group :cols="3">
                  <div class="col-span-2 mr-3">
                    <vert-field-group label="Host Name" required>
                      <text-field v-model="product.sftpHostName" name="vifeed.sftpHostName" :disabled="!product.enabled" />
                      <template #validation-message>
                        <FieldErrorMessage :field="v$.product.sftpHostName"></FieldErrorMessage>
                      </template>
                    </vert-field-group>
                  </div>
                  <div>
                    <vert-field-group label="Port">
                      <text-field v-model="product.sftpPort" name="vifeed.sftpPort" :disabled="!product.enabled" />
                      <template #validation-message>
                        <FieldErrorMessage :field="v$.product.sftpPort"></FieldErrorMessage>
                      </template>
                    </vert-field-group>
                  </div>
                </column-group>

                <div class="mt-5">
                  <vert-field-group label="Directory">
                    <text-field v-model="product.sftpDirectory" name="vifeed.sftpDirectory" :disabled="!product.enabled" />
                    <template #validation-message>
                      <FieldErrorMessage :field="v$.product.sftpDirectory"></FieldErrorMessage>
                    </template>
                  </vert-field-group>
                </div>

                <column-group :cols="3" class="mt-5">
                  <div class="col-span-2 mr-3">
                    <vert-field-group label="Username" required>
                      <text-field v-model="product.sftpUsername" name="vifeed.sftpUsername" :disabled="!product.enabled" />
                      <template #validation-message>
                        <FieldErrorMessage :field="v$.product.sftpUsername"></FieldErrorMessage>
                      </template>
                    </vert-field-group>
                  </div>
                  <div>
                    <vert-field-group label="Password" required>
                      <password-field v-model="product.sftpPassword" name="vifeed.sftpPassword" :disabled="!product.enabled" />
                      <template #validation-message>
                        <FieldErrorMessage :field="v$.product.sftpPassword"></FieldErrorMessage>
                      </template>
                    </vert-field-group>
                  </div>
                </column-group>

                <div class="mt-6">
                  <btn color="blue" styleType="pill" @click="onTestCredentialsClick">{{testButtonText}}</btn>
                </div>
              </div>

              <Transition>
                <alert v-if="alertState.testSuccessAlert" style-type="success" class="mt-4">
                  Credentials Tested Successfully.
                </alert>
              </Transition>
              <Transition>
                <alert v-if="alertState.testFailedAlert" style-type="warning" class="mt-4">
                  There was a problem testing your credentials, please double check correctness.
                </alert>
              </Transition>
            </div>

            <separator />

            <validated-field-group label="VI Dashboard Data">
              <template #details>
                Enable this product to access Brand Insights
              </template>
              <btn v-if="product.dashboardDataEnabled " @click="onDisableDBDataClick">Disable</btn>
              <btn v-if="!product.dashboardDataEnabled" @click="onEnableDBDataClick">Enable</btn>
            </validated-field-group>
          </div>

          <template v-slot:footer>
            <btn type="submit" color="orange" :is-disabled="!product.enabled || saving">{{ saving ? "Saving..." : "Save" }}</btn>
            <Transition>
              <alert v-if="alertState.saveSuccessAlert" style-type="success" class="mt-4">
                Your changes have been saved.
              </alert>
            </Transition>
            <Transition>
              <alert v-if="alertState.saveFailedAlert" style-type="warning" class="mt-4">
                There was a problem saving your changes. Please try again later.
              </alert>
            </Transition>
            <Transition>
              <alert v-if="alertState.existingS3DataAlert" style-type="warning" class="mt-4">
                Credentials for S3 Shipments already exist for this account. Saving this data will overwrite them.
              </alert>
            </Transition>
            <Transition>
              <alert v-if="alertState.testRequiredAlert" style-type="warning" class="mt-4">
                You must test your credentials before saving them, to ensure that they are valid.
              </alert>
            </Transition>
            <Transition>
              <alert v-if="formInvalid" style-type="warning" class="mt-4">
                Please correct the errors in the form and resubmit.
              </alert>
            </Transition>
          </template>
        </card>
      </form>
    </div>

    <Transition>
      <product-disable-confirm-modal :show="showConfirmModal" product-name="VI Feed" @cancel="modalCancelHandler" @confirm="modalConfirmHandler" />
    </Transition>
    <Transition>
      <product-disable-confirm-modal :show="showDBConfirmModal" product-name="VI Feed Dashboard Data" @cancel="dbModalCancelHandler" @confirm="dbModalConfirmHandler" />
    </Transition>
  </div>
</template>

<script>
  import { Alert, Btn, Card, ColumnGroup, DatePicker, Dropdown, LoadingSpinner, RadioGroupItem, RadioGroup, Separator, TextField } from "@bombora/component-library";

  import AccountBreadcrumbs from "@/components/shared/AccountBreadcrumbs";
  import FieldErrorMessage from "@/components/shared/FieldErrorMessage";
  import ProductDisableConfirmModal from "@/components/shared/ProductDisableConfirmModal";
  import ValidatedFieldGroup from "@/components/shared/ValidatedFieldGroup.vue";
  import ValidatedVertFieldGroup from "@/components/shared/ValidatedVertFieldGroup.vue";
  import PasswordField from "@/components/shared/PasswordField";

  import { useVuelidate } from '@vuelidate/core'
  import { required, requiredIf, helpers } from '@vuelidate/validators'

  const productName = 'vi_feed';

  let frequencies = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
  ];
  let s3Keys = ['s3Bucket', 's3Region', 's3AccessKey', 's3SecretKey'];
  let sftpKeys = ['sftpHostName', 'sftpDirectory', 'sftpPort', 'sftpUsername', 'sftpPassword'];
  let requiredString = (fieldName) => `${fieldName} is required.`

  export default {
    name: "ProductVIFeed",
    components: {
      AccountBreadcrumbs,
      Alert,
      Btn,
      Card,
      ColumnGroup,
      DatePicker,
      Dropdown,
      FieldErrorMessage,
      LoadingSpinner,
      ProductDisableConfirmModal,
      PasswordField,
      RadioGroupItem,
      RadioGroup,
      Separator,
      TextField,
      ValidatedFieldGroup,
      vertFieldGroup: ValidatedVertFieldGroup,
    },
    setup: () => ({ v$: useVuelidate() }),
    // inherited from pages/account/Page
    props: ["account", "loading"],
    data() {
      return {
        product: {
          deliveryMethod: 'sftp',
          deliveryFrequency: 'daily',
          sftpPort: '22',
          sftpDirectory: '/',
          // Properties that will not come on the product object
          s3AccessKey: '',
          s3SecretKey: '',
          sftpUsername: '',
          sftpPassword: '',
        },
        saving: false,
        // Alert states
        alertState: {
          saveSuccessAlert: false,
          saveFailedAlert: false,
          testSuccessAlert: false,
          testFailedAlert: false,
          testRequiredAlert: false,
          existingS3DataAlert: false,
          formInvalidAlert: false,
        },
        // Do not allow save without a success test first
        tested: false,
        showConfirmModal: false,
        showDBConfirmModal: false,
        frequencies: frequencies,
        testButtonText: "Test Credentials"
      }
    },
    mounted() {
      this.getDataFromAccount()
    },
    validations() {
      return {
        product: {
          deliveryMethod: {
            required: helpers.withMessage(requiredString("Delivery Method"), required)
          },
          deliveryFrequency: {
            required: helpers.withMessage(requiredString("Delivery Frequency"), required)
          },
          s3Bucket: {
            required: helpers.withMessage(requiredString("S3 Bucket"), requiredIf(this.product.deliveryMethod === "s3")),
          },
          s3AccessKey: {
            required: helpers.withMessage(requiredString("S3 Access Key"), requiredIf(this.product.deliveryMethod === "s3")),
          },
          s3SecretKey: {
            required: helpers.withMessage(requiredString("S3 Secret Key"), requiredIf(this.product.deliveryMethod === "s3")),
          },
          sftpHostName: {
            required: helpers.withMessage(requiredString("SFTP Host Name"), requiredIf(this.product.deliveryMethod === "sftp")),
          },
          sftpDirectory: {
            required: helpers.withMessage(requiredString("SFTP Directory"), requiredIf(this.product.deliveryMethod === "sftp")),
          },
          sftpPort: {
            required: helpers.withMessage(requiredString("SFTP Port"), requiredIf(this.product.deliveryMethod === "sftp")),
          },
          sftpUsername: {
            required: helpers.withMessage(requiredString("SFTP Username"), requiredIf(this.product.deliveryMethod === "sftp")),
          },
          sftpPassword: {
            required: helpers.withMessage(requiredString("SFTP Password"), requiredIf(this.product.deliveryMethod === "sftp")),
          },
        }
      }
    },
    watch: {
      account: {
        handler: "getDataFromAccount",
        immediate: true
      },
      'product.deliveryMethod'(newValue) {
        // Watch the value for delivery method and set tested, to force a retest
        this.tested = false;
      }
    },
    methods: {
      clearAlerts() {
        this.alertState.saveSuccessAlert = false;
        this.alertState.saveFailedAlert = false;
        this.alertState.testSuccessAlert = false;
        this.alertState.testFailedAlert = false;
        this.alertState.testRequiredAlert = false;
        this.alertState.existingS3DataAlert = false;
        this.alertState.formInvalidAlert = false;
      },
      triggerAlert(state) {
        this.alertState[state] = true;
        setTimeout(() => this.alertState[state] = false, 5000);
      },
      onTestCredentialsClick() {
        this.clearAlerts();
        this.testButtonText = "Testing...";

        this.$api.testSFTP(
          {
            host: this.product.sftpHostName,
            port: this.product.sftpPort,
            directory: this.product.sftpDirectory,
            username: this.product.sftpUsername,
            password: this.product.sftpPassword
          }
        )
          .then((res) => {
            if (res.data) {
              this.triggerAlert('testSuccessAlert');
              this.tested = true;
            } else {
              this.triggerAlert('testFailedAlert');
            }
            this.testButtonText = "Test Credentials";
          })
          .catch((err) => {
            this.triggerAlert('testFailedAlert');
            this.testButtonText = "Test Credentials";
          })
      },
      /// PRODUCT
      onDisableProductClick() {
        this.clearAlerts();
        // If the product is currently active, show the modal for disable
        this.showConfirmModal = true;
      },
      modalCancelHandler() {
        this.showConfirmModal = false;
      },
      modalConfirmHandler() {
        this.product.enabled = false;
        this.clearAlerts();
        this.modalCancelHandler();
      },
      onEnableProductClick() {
        this.product.enabled = true;
      },
      /// END PRODUCT
      /// DASHBOARD DATA
      onDisableDBDataClick() {
        // If currently active, show the modal for disable
        this.showDBConfirmModal = true;
      },
      dbModalCancelHandler() {
        this.showDBConfirmModal = false;
      },
      dbModalConfirmHandler() {
        this.product.dashboardDataEnabled = false;
        this.dbModalCancelHandler();
      },
      onEnableDBDataClick() {
        this.product.dashboardDataEnabled = true;
      },
      /// END DASHBOARD DATA
      async onSubmit() {
        // Only check form if product is enabled, we don't want to throw errors if the product is off.
        if (this.product.enabled) {
          const isFormCorrect = await this.v$.$validate()
          if (!isFormCorrect) {
            console.debug(this.v$.$errors);
            this.triggerAlert('formInvalidAlert');
            return
          }
        }

        if (false) {
          // TODO: If there are existing values in the form for S3 when
          // the user tries to save updated data, warn them on overwrite.
          this.triggerAlert('existingS3DataAlert');
          return;
        }
        if (this.product.deliveryMethod === 'sftp' && !this.tested) {
          this.triggerAlert('testRequiredAlert');
          return;
        }

        this.saving = true;
        // Null out values from opposite delivery method
        if (this.product.deliveryMethod === 's3') {
          this.nullUnusedValues(sftpKeys);
        } else if (this.product.deliveryMethod === 'sftp') {
          this.nullUnusedValues(s3Keys);
        }

        this.$api.updateProduct(this.account.id, productName, this.product)
          .then(() => {
            this.saving = false;
            this.triggerAlert('saveSuccessAlert');
          })
          .catch((err) => {
            this.saving = false;
            this.triggerAlert('saveFailedAlert');
            console.error(err.message)
          })
      },
      nullUnusedValues(keys) {
        // Null the data model and the form values
        keys.forEach(k => {
          this.product[k] = null;
        });
      },
      getDataFromAccount() {
        if (this.account && this.account.products) {
          // Keep the defaults if the product coming back does not specify.
          this.product = { ...this.product, ...this.account.products.find(p => p.id === productName) }
          if (this.product.deliveryMethod == null || this.product.deliveryMethod == '') {
            this.product.deliveryMethod = 'sftp';
          }
        }
      },
    }
  };
</script>
