import accountActions from './accountActions';
import userActions from './userActions';
import KeywordTopicActions from "@/store/keywordTopicActions";
import domainAppendActions from "@/store/domainAppendActions";

export default api => {
    return {
        // Account Controller Actions
        ...accountActions(api),
        // User Controller Actions
        ...userActions(api),
        // Keyword to Topic Controller Actions
        ...KeywordTopicActions(api),
        // Domain Append Controller Actions
        ...domainAppendActions(api),
    }
}
