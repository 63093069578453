<template>
  <div class="main-content">
    <div class="flex">
      <div class="flex-1">
        <card>
          <template v-slot:header>
            <column-group :cols="3">
              <div>
                <h3 class="text-3xl font-bold">Domain Append</h3>
                <p class="text-dark-gray">All dates are local time.</p>
              </div>
              <div class="justify-self-end inline-block col-span-2">
                <Btn color="orange" @click="triggerModal">Upload CSV</Btn>
                <Btn color="blue-line" :class="{ disabled: refreshing }" :disabled="refreshing" @click="refreshTable">
                  <spinner-icon v-if="refreshing" width="16" height="16"></spinner-icon> Refresh Table
                </Btn>
              </div>
            </column-group>
          </template>
          <div>
            <Transition>
              <Alert v-if="downloadFailed" style-type="error" class="mt-6 mb-6">
                {{downloadErrorMessage}}
              </Alert>
            </Transition>
          </div>
          <grid v-if="loaded && rows.length > 0">
            <template #gridHeaderCells class="table-fixed">
              <grid-header-cell column-key="Id">Id</grid-header-cell>
              <grid-header-cell column-key="CreatedDate" :sortable="true" :sortDirection="createdDateSortOrder" @table-sorted="toggleCreatedDateSort">Created Date</grid-header-cell>
              <grid-header-cell column-key="FileName" :sortable="true" :sortDirection="fileNameSortOrder" @table-sorted="toggleFileNameSort">File Name</grid-header-cell>
              <grid-header-cell column-key="Status" :sortable="true" :sortDirection="statusSortOrder" @table-sorted="toggleStatusSort">Status</grid-header-cell>
              <grid-header-cell column-key="Email" :sortable="true" :sortDirection="emailSortOrder" @table-sorted="toggleEmailSort">Email</grid-header-cell>
              <grid-header-cell column-key="TotalRows">Total Rows</grid-header-cell>
              <grid-header-cell column-key="MatchedRows">Matched Rows</grid-header-cell>
              <grid-header-cell column-key="Download" class="w-12"></grid-header-cell>
            </template>
            <template #rows>
              <template v-for="dataRow in paginatedRows" :key="dataRow.id">
                <grid-row>
                  <grid-cell>{{ dataRow.id }}</grid-cell>
                  <grid-cell>{{ tryGetDateTimeString(dataRow.createdDate, true) }}</grid-cell>
                  <grid-cell>
                    <ellipsis-text-block :text="dataRow.originalFileName" :length=23></ellipsis-text-block>
                  </grid-cell>
                  <grid-cell>
                    <div class="w-24 text-center border-2 rounded text-sm"
                         :class="{'bg-green-lightest text-green' : dataRow.status === 'Complete',
                         'bg-blue-light text-blue': dataRow.status === 'Pending',
                         'bg-yellow-lightest text-yellow': dataRow.status === 'Processing',
                         'bg-red-light text-red': dataRow.status === 'Error'}"
                    >
                      {{ dataRow.status }}
                    </div>
                  </grid-cell>
                  <grid-cell>
                    <ellipsis-text-block :text="dataRow.email" :length=19></ellipsis-text-block>
                  </grid-cell>
                  <grid-cell>{{ dataRow.totalRows }}</grid-cell>
                  <grid-cell>{{ dataRow.matchedRows }}</grid-cell>
                  <grid-cell>
                    <button v-if="dataRow.status === 'Complete'" @click="downloadDomainFile(dataRow.id)">
                      <icon icon-type="download" class="w-4 h-4 text-blue-darkest"></icon>
                    </button>
                  </grid-cell>
                </grid-row>
              </template>
            </template>
          </grid>
          <div v-if="!loaded" class="text-center">
            <div class="inline-block">
              <loading-spinner />
            </div>
          </div>
          <!-- display this message if there's no row data -->
          <div v-if="loaded && rows.length === 0" class="text-blue-darkest font-semibold text-center">There are no records to display.</div>
          <template #footer>
            <grid-pager :page-size="pageSize"
                        :total-pages="totalPages"
                        :current-page="currentPage"
                        @paged="pageNumClick"></grid-pager>
          </template>
        </card>
      </div>
    </div>

    <Transition>
      <modal v-if="showUploadModal" @close="handleClose">
        <form @submit.prevent="onSubmit">
          <div class="px-5 mb-4">
            <!-- Modal Header-->
            <div class="mb-5">
              <h1 class="text-3xl flex font-bold">Upload Domain File</h1>
              <p class="text-gray">Files with the following characters in the name will be rejected: &lt; &gt;</p>
            </div>
            <!-- User Information Text Inputs -->
            <div class="mb-4">
              <div class="flex flex-row">
                <file-drop-zone data-id="domain-upload-action" @updated="onFileUploaded" :accept="'.csv'">
                  <div class="w-full h-full grid place-content-center">
                    <div class="w-8 h-8 place-self-center">
                      <icon icon-type="upload" class="w-6 h-6"></icon>
                    </div>
                    <p class="text-dark-gray">Click to upload or drag and drop your CSV files</p>
                  </div>
                </file-drop-zone>
              </div>
              <div v-if="currentFileName !==  null">
                <div class="p-2 border-dark-gray bg-gray-lightest mb-5 rounded">
                  {{currentFileName}}
                </div>
              </div>
              <div class="flex flex-row">
                <toggle :checked="false"
                        v-model="includeAllDomains">
                  <template #leftLabel>
                    Include all Domains in Output?
                  </template>
                </toggle>
              </div>
            </div>

            <div class="w-full left-0 bottom-0">
              <div class="my-2">
                <btn type="submit" color="orange" :class="{ disabled: uploading }" :disabled="uploading" @click="uploadDomainFile">
                  <spinner-icon v-if="uploading" width="16" height="16"></spinner-icon>
                  Upload File
                </btn>
                <Transition>
                  <alert v-if="saveSuccess" style-type="success" class="mt-4">
                    File uploaded successfully.
                  </alert>
                </Transition>
                <Transition>
                  <Alert v-if="saveFailed" style-type="warning" class="mt-4">
                    {{uploadErrorMessage}}
                  </Alert>
                </Transition>
              </div>
            </div>
          </div>
        </form>
      </modal>
    </Transition>
  </div>
</template>

<script>
  import {
    Alert,
    Btn,
    Card,
    ColumnGroup,
    Dropdown,
    Grid,
    GridCell,
    GridHeaderCell,
    GridPager,
    GridRow,
    LoadingSpinner,
    SpinnerIcon,
    Toggle,
    Modal
  } from "@bombora/component-library";
  import Icon from "@/components/shared/Icon.vue";
  import FileDropZone from "@/components/shared/FileDropZone.vue";
  import EllipsisTextBlock from "@/components/shared/EllipsisTextBlock.vue";

  import {tryGetDateTimeString} from "@/helpers.js";

  export default {
    name: "DomainAppend",
    components: {
      Alert,
      Btn,
      Card,
      ColumnGroup,
      Dropdown,
      Grid,
      GridCell,
      GridHeaderCell,
      GridPager,
      GridRow,
      Icon,
      LoadingSpinner,
      SpinnerIcon,
      Toggle,
      Modal,
      FileDropZone,
      EllipsisTextBlock,
    },
    props: {},
    data: function () {
      return {
        rows: [],
        currentPage: 1,
        pageSize: 10,
        loaded: false,
        refreshing: false,
        uploading: false,
        userCanAddTopics: true,
        showUploadModal: false,
        saveSuccess: false,
        saveFailed: false,
        downloadFailed: false,
        defaultUploadErrorMessage: 'There was a problem uploading your domain file. Please try again later.',
        defaultDownloadErrorMessage: 'There was a problem downloading your output file. Please try again later.',
        downloadErrorMessage: this.defaultDownloadErrorMessage,
        uploadErrorMessage: this.defaultUploadErrorMessage,
        includeAllDomains: false,
        domainFormData: null,
        currentFileName: null,
        fileNameSortOrder: "asc",
        statusSortOrder: null,
        emailSortOrder: null,
        createdDateSortOrder: null
      };
    },
    async mounted() {
      this.rows = (await this.$api.getReports()).data;
      this.rows.sort(this.sortByFileName)
      this.loaded = true;
    },
    watch: {
    },
    methods: {
      onFileUploaded(files) {
        let fileToUpload = files[0];
        this.domainFormData = new FormData();
        this.domainFormData.append('file', fileToUpload)
        this.domainFormData.append('email', this.$auth.user.value.email)
        this.domainFormData.append('getAllData', this.includeAllDomains)
        this.currentFileName = fileToUpload.name
      },
      async uploadDomainFile() {
        this.uploading = true;
        let response = await this.$api.uploadReport(this.domainFormData)
        this.uploading = false;
        this.domainFormData = null;
        this.currentFileName = null;
        if (response.status === 200) {
          this.saveSuccess = true;
          await this.refreshTable();
        } else if (response.statusCode === 400) {
          this.uploadErrorMessage = response.data;
          this.saveFailed = true;
        } else if (response.statusCode === 422) {
          this.uploadErrorMessage = "Please provide a file to upload.";
          this.saveFailed = true;
        } else {
          this.uploadErrorMessage = this.defaultUploadErrorMessage;
          this.saveFailed = true;
        }
        setTimeout(() => {
          this.saveFailed = false;
          this.saveSuccess = false;
        }, 5000)
      },
      async downloadDomainFile(id) {
        let response = await this.$api.downloadReport(id);
        if (response.statusCode === 400) {
          this.downloadErrorMessage = response.data;
          this.downloadFailed = true;
        } else if (response.statusCode === 500) {
          this.downloadErrorMessage = this.defaultDownloadErrorMessage;
          this.downloadFailed = true;
        }
        setTimeout(() => {
          this.downloadFailed = false;
        }, 5000)
      },
      triggerModal() {
        this.saveSuccess = false;
        this.saveFailed = false;
        this.showUploadModal = true;
      },
      handleClose() {
        this.showUploadModal = false;
        this.domainFormData = null;
        this.currentFileName = null;
      },
      async refreshTable() {
        this.loaded = false;
        this.refreshing = true;
        let response = await this.$api.getReports();
        this.rows = response.data;
        this.rows.sort(this.sortByFileName)
        this.refreshing = false;
        this.loaded = true;
      },
      pageNumClick(pageNum) {
        this.currentPage = pageNum
      },
      toggleFileNameSort() {
        this.statusSortOrder = null;
        this.emailSortOrder = null;
        this.createdDateSortOrder = null;

        this.fileNameSortOrder = this.fileNameSortOrder === "desc" ? "asc" : "desc";
        this.rows.sort(this.sortByFileName);
      },
      sortByFileName(a, b) {
        if(this.fileNameSortOrder === "desc") {
          return b.originalFileName.localeCompare(a.originalFileName);
        } else if(this.fileNameSortOrder ===  "asc") {
          return a.originalFileName.localeCompare(b.originalFileName);
        }
      },
      toggleStatusSort() {
        this.fileNameSortOrder = null;
        this.emailSortOrder = null;
        this.createdDateSortOrder = null;

        this.statusSortOrder = this.statusSortOrder === "desc" ? "asc" : "desc";
        this.rows.sort(this.sortByStatus)
      },
      sortByStatus(a, b) {
        const order = ["pending", "processing", "complete", "error"];

        if(this.statusSortOrder === "desc") {
          return order.indexOf(b.status.toLowerCase()) - order.indexOf(a.status.toLowerCase());
        } else if(this.statusSortOrder === "asc") {
          return order.indexOf(a.status.toLowerCase()) - order.indexOf(b.status.toLowerCase());
        }
      },
      toggleEmailSort() {
        this.fileNameSortOrder = null;
        this.statusSortOrder = null;
        this.createdDateSortOrder = null;

        this.emailSortOrder = this.emailSortOrder === "desc" ? "asc" : "desc"
        this.rows.sort(this.sortByEmail)
      },
      sortByEmail(a, b) {
        if(this.emailSortOrder === "desc") {
          return b.email.localeCompare(a.email);
        } else if(this.emailSortOrder === "asc") {
          return a.email.localeCompare(b.email);
        }
      },
      toggleCreatedDateSort() {
        this.fileNameSortOrder = null;
        this.statusSortOrder = null;
        this.emailSortOrder = null;

        this.createdDateSortOrder = this.createdDateSortOrder === "desc" ? "asc" : "desc"
        this.rows.sort(this.sortByCreatedDate)
      },
      sortByCreatedDate(a, b) {
        if (this.createdDateSortOrder === "desc") {
          return new Date(b.createdDate) - new Date(a.createdDate);
        } else if(this.createdDateSortOrder === "asc") {
          return new Date(a.createdDate) - new Date(b.createdDate);
        }
      },
      tryGetDateTimeString: tryGetDateTimeString,
    },
    computed: {
      paginatedRows() {
        return this.rows.slice((this.currentPage - 1) * this.pageSize, ((this.currentPage - 1) * this.pageSize) + this.pageSize)
      },
      totalPages() {
        return Math.ceil(this.rows.length / this.pageSize)
      }
    }
  };
</script>

<style scoped>

</style>
