<template>
  <div class="main-content">
    <div class="flex">
      <div class="flex-1">
        <card>
          <template v-slot:header>
            <column-group :cols="3">
              <div>
                <h3 class="text-3xl font-bold">Manage Taxonomy</h3>
              </div>
              <div class="justify-self-end inline-block col-span-2">
                <Btn color="orange" @click="" :is-disabled="!userCanAddTopics">
                  Add New
                </Btn>
              </div>
            </column-group>
          </template>
          <column-group>
            <div>
              <h3 class="pr-4 pl-4 pb-2.5 text-left text-2xl">Themes</h3>
              <div class="border border-gray-lightest py-2">
                <div v-for="(dataRow, index) in themes" :key="dataRow.id" class="mx-2">
                  <column-group :class="[dataRow.expanded ? 'bg-blue-light' : '', 'p-2']">
                    <div>
                      <button class="w-10" @click="cellClicked(index)" aria-label="Expand">
                        <Icon class="h-4 w-4"
                              :iconType="dataRow.expanded ? 'minus' : 'plus'" />
                      </button>
                      <span @click="cellClicked(index)" class="cursor-pointer">{{ dataRow.name }} ({{ dataRow.id }})</span>
                    </div>
                    <div class="text-right">
                      <btn class="w-min" color="blue-line" size="small">Rename</btn>
                    </div>
                  </column-group>
                  <div v-if="dataRow.expanded" :class="[dataRow.expanded ? 'bg-gray-lightest' : '', 'pl-6 py-3 pr-3']">
                    <div v-for="(detailRow, index) in dataRow.categories" :key="detailRow.id" class="py-1">
                      <column-group>
                        <div>
                          <button class="w-full text-left" @click="" aria-label="Show Topics">
                            {{ detailRow.name }} ({{ detailRow.id }})
                          </button>
                        </div>
                        <div class="text-right">
                          <btn class="w-min" color="blue-line" size="small">Edit</btn>
                        </div>
                      </column-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <grid v-if="loaded && topics.length > 0" class="pl-4">
              <template #gridHeaderCells>
                <grid-header-cell @table-sorted="() => sort('sortByName')">Topics</grid-header-cell>
                <grid-header-cell column-key="Details" :sortable="false">
                  <btn color="blue-line" size="small">Add Topic</btn>
                </grid-header-cell>
              </template>
              <template #rows>
                <template v-for="(dataRow, index) in topics" :key="dataRow.id">
                  <grid-row>
                    <grid-cell>{{ dataRow.name }} ({{ dataRow.id }})</grid-cell>
                    <grid-cell>
                      <router-link :to="{ name: 'AccountInfo', params: { id: `${dataRow.id}` }}">
                        <btn color="blue-line" size="small">Edit</btn>
                      </router-link>
                    </grid-cell>
                  </grid-row>
                </template>
              </template>
            </grid>
            <card>
              <template v-slot:header>
                Edit Topic
              </template>
              <dropdown placeholder="Theme"
                        name="theme"
                        :fullWidth="true"
                        :items="products"
                        v-model="editingTopic.theme"
                        class="mr-3" />
              <dropdown placeholder="Category"
                        name="category"
                        :fullWidth="true"
                        :items="entityTypes"
                        v-model="editingTopic.category" />
              <text-field placeholder="Topic Name"
                          name="TopicName"
                          :fullWidth="true"
                          class="mr-3"
                          :modelValue="editingTopic.name"
                          @update:modelValue="onSearch">
              </text-field>
            </card>
          </column-group>
          <div v-if="!loaded" class="text-center">
            <div class="inline-block">
              <loading-spinner />
            </div>
          </div>
          <!-- display this message if there's no row data -->
          <div v-if="loaded && themes.length == 0" class="text-blue-darkest font-semibold text-center">There was an error and Topice were not loaded. Please refresh the page.</div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Btn,
    Card,
    ColumnGroup,
    Dropdown,
    Grid,
    GridCell,
    GridHeaderCell,
    GridPager,
    GridRow,
    LoadingSpinner,
    SpinnerIcon,
    TextField,
  } from "@bombora/component-library";

  import Icon from "@/components/shared/Icon.vue";

  export default {
    name: "Taxonomy",
    components: {
      Btn,
      Card,
      ColumnGroup,
      Dropdown,
      Grid,
      GridCell,
      GridHeaderCell,
      GridPager,
      GridRow,
      Icon,
      LoadingSpinner,
      SpinnerIcon,
      TextField,
    },
    props: {},
    data: function () {
      return {
        themes: [
          { name: 'BioTech', id: '123', categories: [{ name: 'Education', id: '234' }, { name: 'Health', id: '235' }, { name: 'HR', id: '236' }] },
          { name: 'Buisness', id: '124', categories: [{ name: 'Education', id: '234' }, { name: 'Health', id: '235' }, { name: 'HR', id: '236' }] },
          { name: 'Company', id: '125', categories: [{ name: 'Education', id: '234' }, { name: 'Health', id: '235' }, { name: 'HR', id: '236' }] },
          { name: 'Finance', id: '126', categories: [{ name: 'Education', id: '234' }, { name: 'Health', id: '235' }, { name: 'HR', id: '236' }] },
          { name: 'Legal', id: '127', categories: [{ name: 'Education', id: '234' }, { name: 'Health', id: '235' }, { name: 'HR', id: '236' }] },
          { name: 'Marketing', id: '128', categories: [{ name: 'Education', id: '234' }, { name: 'Health', id: '235' }, { name: 'HR', id: '236' }] },
          { name: 'Media', id: '129', categories: [{ name: 'Education', id: '234' }, { name: 'Health', id: '235' }, { name: 'HR', id: '236' }] },
        ],
        topics: [
          { name: 'Accounting', id: '123' },
          { name: 'Commodity', id: '124' },
          { name: 'Finance IT', id: '125' },
          { name: 'Other', id: '126' },
          { name: 'Personal Finance', id: '127' },
          { name: 'Investing', id: '128' },
          { name: 'Payments', id: '129' },],
        editingTopic: {},
        loaded: true,
        userFileDownloading: false,
        accountFileDownloading: false,
        userCanAddTopics: true,
        hasReadUserPermission: false,
        hasReadAccountPermission: false
      };
    },
    mounted() {
    },
    watch: {
    },
    methods: {
      onSearch(value) {
        // Set the search value to trigger the watch.
        this.listParams.search = value;
        this.listParams.page = 1;
      },
      checkReadUsersPermission() {
        this.$auth.userHasPermission("read:users")
          .then(allowed => { this.hasReadUserPermission = allowed });
      },
      checkReadAccountPermission() {
        this.$auth.userHasPermission("read:account")
          .then(allowed => { this.hasReadAccountPermission = allowed });
      },
      goToEmptyAccountInfo() {
        this.$router.push({ name: 'AccountInfo', params: { id: 0 } })
      },
      cellClicked(rowIndex) {
        if (this.themes[rowIndex].expanded == undefined) {
          this.themes[rowIndex].expanded = true;
        } else {
          this.themes[rowIndex].expanded =
            !this.themes[rowIndex].expanded;
        }
        // Close all other detail rows.
        //this.themes.forEach((e, i) => {
        //  if (e.expanded != undefined && i != rowIndex) e.expanded = false;
        //});
      },
    },
    computed: {
    }
  };
</script>

<style scoped></style>
